import React from 'react';
import fourthSection from "../../../components/assets/img/pages/landing/guy-with-phone.png";

const FourthSection = () => {
  return (
    <section className="fourth padding-7">
      <div className="wrapper">
        <div className="left">
          <h1 className="title yellow-font bold">Como funciona o Gifthy</h1>
          <p className="description blue-font">Você cria um perfil e escolhe os presentes que gostaria de receber. Convida seus amigos, que farão o mesmo.</p>
          <h1 className="title no-margin blue-font bold">Pronto!</h1>
          <h1 className="title no-margin yellow-font bold">Está formada a rede.</h1>
          <p className="description blue-font text-justify">Quando chegar, por exemplo, o aniversário de um participante, seus amigos serão lembrados com antecedência e saberão quais presentes poderão comprar. Se o participante não escolher, o Gifthy indicará, com base em seu estilo de vida. Sem risco de errar! Além disso, vários amigos podem dividir a compra de um item de maior valor, possibilitando ofertar um presente inesquecível.</p>
        </div>
        <div className="right">
          <div>
            <img className="img" src={fourthSection} alt="como funciona o gifthy"/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FourthSection;